<template>
  <v-container class="py-8" id="project">
    <section>
      <h2 class="section-title">Project</h2>
    </section>
    <v-row justify="space-between" align="end" class="project-wrapper">
      <v-col md="4" sm="6" v-for="(work, i) in works" :key="i">
        <v-hover v-slot="{ hover }">
          <v-card :class="{ 'on-hover': hover }" @click="openNewTab(work.url)">
            <v-img :src="work.src" :lazy-src="work.src" aspect-ratio="1.7778" />
            <v-card-title class="text-center">{{ work.title }}</v-card-title>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  getFirestore,
  getDocs,
  collection,
  query,
  orderBy,
} from "firebase/firestore";
export default {
  data() {
    return {
      works: [],
    };
  },
  methods: {
    openNewTab(url) {
      window.open(url, "_blank");
    },
  },
  async mounted() {
    const db = getFirestore();
    const worksRef = collection(db, "works");
    const q = query(worksRef, orderBy("id", "asc"));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      this.works.push(doc.data());
    });
  },
};
</script>

<style scoped lang="scss">
@media screen and (min-width: 900px) {
  .v-card {
    transition: opacity 0.4s ease-in-out;
  }

  .v-card:not(.on-hover) {
    opacity: 0.6;
  }
}

.v-card__title {
  justify-content: center;
  font-size: 1.8rem;
}

.v-card {
  cursor: pointer;
}

.project-wrapper::after {
  content: "";
  display: block;
  width: 33.3333333333%;
}

@media screen and (max-width: 599px) {
  .col {
    flex-basis: 100%;
  }
}
</style>