<template>
  <v-app dark>
    <v-app-bar clipped-right app>
      <v-toolbar-title @click="scroll(0)" :class="weight" v-text="title" />
      <v-spacer />
      <v-toolbar-items class="hidden-sm-and-down">
        <v-btn
          large
          text
          v-for="(menu, i) in menus"
          :key="i"
          :class="weight"
          link
          :href="menu.href ? menu.href : ''"
          @click="menu.id ? scroll(menu.id) : ''"
          style="text-transform: none"
          >{{ menu.title }}</v-btn
        >
      </v-toolbar-items>
      <v-menu bottom rounded offset-y min-width="200px">
        <template v-slot:activator="{ on }">
          <v-btn icon x-large v-on="on" class="hidden-sm-and-down">
            <v-avatar size="48">
              <img src="@/assets/icon.jpg" alt="icon" />
            </v-avatar>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="item in items"
            :key="item.id"
            link
            @click="openNewTab(item.url)"
          >
            <v-list-item-icon>
              <v-icon x-large>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title :class="weight" v-text="item.name" />
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn icon @click.stop="drawer = !drawer" class="hidden-md-and-up">
        <v-icon>mdi-menu</v-icon>
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" right temporary fixed>
      <v-list>
        <v-list-item class="justify-end">
          <v-icon large @click="drawer = !drawer">mdi-close</v-icon>
        </v-list-item>
        <v-list-item
          v-for="(menu, i) in menus"
          :key="i"
          link
          :href="menu.href ? menu.href : ''"
          @click="menu.id ? scroll(menu.id) : ''"
        >
          <v-list-item-content>
            <v-list-item-title
              :class="[weight, 'text-center']"
              v-text="menu.title"
            />
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <Hero></Hero>
      <About></About>
      <Works></Works>
      <Skills></Skills>
    </v-main>

    <v-bottom-navigation app hide-on-scroll>
      <v-btn
        text
        v-for="item in items"
        :key="item.id"
        @click="openNewTab(item.url)"
        style="height: 100%"
      >
        <v-icon x-large>{{ item.icon }}</v-icon>
      </v-btn>
      <!-- <small>
        &copy; {{ new Date().getFullYear() }} DAICHI NABECIMA All Rights
        Reserved
      </small> -->
    </v-bottom-navigation>
  </v-app>
</template>

<script>
import {
  getFirestore,
  getDocs,
  collection,
  query,
  orderBy,
} from "firebase/firestore";
import Hero from "./components/Hero.vue";
import About from "./components/About.vue";
import Works from "./components/Works.vue";
import Skills from "./components/Skill.vue";
export default {
  components: {
    Hero,
    About,
    Works,
    Skills,
  },
  data() {
    return {
      drawer: false,
      right: true,
      weight: "font-weight-black",
      title: "DAICHI NABECIMA",
      items: [],
      menus: [
        {
          id: "#about",
          title: "About",
          href: "",
        },
        {
          id: "#project",
          title: "Project",
          href: "",
        },
        {
          id: "#skill",
          title: "Skill",
          href: "",
        },
        {
         id: "",
         title: "Price",
         href: "https://zippy-tiramisu-8e58f8.netlify.app/",
        },
        // {
        //   id: "#contact",
        //   title: "Contact",
        // },
      ],
    };
  },
  methods: {
    openNewTab(url) {
      window.open(url, "_blank");
    },
    scroll(id) {
      this.$vuetify.goTo(id);
    },
  },
  async mounted() {
    const db = getFirestore();
    const itemsRef = collection(db, "items");
    const q = query(itemsRef, orderBy("id", "asc"));
    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {
      this.items.push(doc.data());
    });
  },
};
</script>

<style>
html {
  font-size: 62.5%;
}

.v-toolbar__title {
  font-family: "Permanent Marker", cursive;
  font-size: 2.5rem !important;
  cursor: pointer;
}

.v-btn__content {
  font-size: 2rem;
  font-family: "Share Tech Mono", monospace;
}

.v-list-item__title {
  font-family: "Share Tech Mono", monospace;
  font-size: 2rem !important;
}

.menu {
  font-family: "Share Tech Mono", monospace;
  font-size: 2.5rem;
}

.section-title {
  font-family: "Share Tech Mono", monospace;
  font-size: 4rem;
  text-align: center;
  margin-bottom: 15px;
}
</style>
