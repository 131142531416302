import Vue from "vue";
import App from "./App.vue";
import { initializeApp } from "firebase/app";
import vuetify from "./plugins/vuetify";

const firebaseConfig = {
  apiKey: "AIzaSyCkdjMsIrbtuOIZ_8sGX4P24xnT61CiWiI",
  authDomain: "portfolio-9015b.firebaseapp.com",
  projectId: "portfolio-9015b",
  storageBucket: "portfolio-9015b.appspot.com",
  messagingSenderId: "873499813772",
  appId: "1:873499813772:web:c7d6c828d635b0b77198a9",
};

initializeApp(firebaseConfig);

Vue.config.productionTip = false;

new Vue({
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
