<template>
  <v-container class="py-8" id="about">
    <section>
      <h2 class="section-title">About</h2>

      <v-card class="mx-auto px-6 py-3" max-width="550">
        <div class="wrapper d-flex align-center flex-sm-row-reverse flex-wrap">
          <div class="d-flex align-center flex-column icon">
            <v-avatar size="170" class="mb-2">
              <img src="@/assets/icon.jpg" alt="icon" />
            </v-avatar>
            <ruby>
              <rb>鍋島 大地</rb>
              <rp>(</rp>
              <rt>なべしま だいち</rt>
              <rp>)</rp>
            </ruby>
          </div>
          <div class="d-flex flex-column profile">
            <p class="job mb-1">職業</p>
            <v-divider></v-divider>
            <p class="position mb-2">コーダー/プログラミング講師</p>
            <p class="desc">{{ desc }}</p>
          </div>
        </div>
      </v-card>
    </section>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      desc:
        // "新卒でSEとして入社。\nUnityを利用したVRアプリ開発に携わる。\nその後、フリーでC#講師として活動中。",
        "新卒でSEとして入社。\nUnityを利用したVRアプリ開発に携わる。\nその後、フリーでコーダー兼プログラミング講師として活動中。\nLPのコーディングが得意で、大手化粧品会社のLPのコーディング経験あり",
    };
  },
};
</script>

<style scoped>
ruby rb {
  font-size: 2rem;
}

.profile {
  max-width: 278px;
}

.job {
  font-size: 1.8rem;
  font-weight: bold;
}

.position {
  font-size: 2rem;
}

.desc {
  font-size: 1.5rem;
  white-space: pre-line;
  line-height: 1.6;
}

.wrapper {
  justify-content: space-between;
  column-gap: 20px;
}

@media screen and (max-width: 544px) {
  .wrapper {
    justify-content: center !important;
  }

  .icon {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 539px) {
  .mx-auto {
    max-width: 360px !important;
  }
}
</style>