<template>
  <v-container id="skill" class="py-8">
    <section>
      <h2 class="section-title">Skill</h2>
      <v-row
        justify="space-around"
        align-content="stretch"
        class="skill-wrapper"
      >
        <v-col
          cols="3"
          v-for="(skill, i) in skills"
          :key="i"
          class="mb-md-4"
          :align-self="skill.align"
        >
          <v-img width="70%" :src="skill.src" class="mx-auto" />
        </v-col>
      </v-row>
    </section>
  </v-container>
</template>

<script>
import {
  getFirestore,
  getDocs,
  collection,
  query,
  orderBy,
} from "firebase/firestore";
export default {
  data() {
    return {
      skills: [],
    };
  },
  async mounted() {
    const db = getFirestore();
    const skillsRef = collection(db, "skills");
    const q = query(skillsRef, orderBy("id", "asc"));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      this.skills.push(doc.data());
    });
  },
};
</script>

<style scoped>
.skill-wrapper::after {
  content: "";
  display: block;
  width: 25%;
}
</style>